<template>
  <div>
    <batch-results-dialog
      :show="showResults"
      :data-type="dataType"
      :successes="successes"
      :failures="failures"
      :value-key="valueKey"
      @close="showResults = false"
    />

    <global-progress
      :visible="showProgress"
      :percentage="progress"
      :message="progressMessage"
    />
  </div>
</template>

<script>
/**
 * Component that handles batch operations.
 * Displays a progress indicator based on the number of requests/operations completed
 * and displays a results dialog to show which have succeeded and failed.
 */
import BatchResultsDialog from '@/components/dialogs/BatchResultsDialog'
import GlobalProgress from '@/components/progress/GlobalProgress'
export default {
  name: 'BatchOperation',
  components: {
    'batch-results-dialog': BatchResultsDialog,
    'global-progress': GlobalProgress
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    operation: {
      type: Function,
      required: true
    },
    callback: {
      type: Function,
      default: () => {}
    },
    dataType: {
      type: String,
      default: 'items'
    },
    valueKey: {
      type: String,
      default: ''
    },
    progressMessage: {
      type: String,
      default: 'Loading...'
    }
  },
  data () {
    return {
      successes: [],
      failures: [],
      showProgress: false,
      showResults: false,
      progress: 0
    }
  },
  methods: {
    /**
     * Handles the batch operation by running the provided asynchronous function for each item concurrently.
     *
     * @param {*} data
     */
    async start (data) {
      this.reset()
      this.showProgress = true

      const promises = []
      let completed = 0
      for (const item of this.items) {
        let result = this.operation(item, data)
        if (!Array.isArray(result)) {
          result = [result]
        }
        for (const promise of result) {
          promises.push(promise.then(() => {
            this.successes.push(item)
          }).catch((err) => {
            this.failures.push({
              data: item,
              error: err.response ? JSON.stringify(err.response.data, null, 2) : null
            })
          }).finally(() => {
            completed++
            this.progress = Math.round(completed / promises.length * 100)
          }))
        }
      }
      await Promise.allSettled(promises)
      this.showProgress = false
      this.showResults = true
      this.callback()
    },
    /**
     * Handles the batch operation by running the provided asynchronous function for each item sequentially.
     *
     * @param {*} data
     */
    async startSequential (data) {
      this.reset()
      this.showProgress = true

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        try {
          await this.operation(item, data)
          this.successes.push(item)
        } catch (err) {
          this.failures.push({
            data: item,
            error: err.response ? JSON.stringify(err.response.data, null, 2) : null
          })
        } finally {
          this.progress = Math.round(i / this.items.length * 100)
        }
      }
      this.showProgress = false
      this.showResults = true
      this.callback()
    },
    /**
     * Resets the batch operation state
     */
    reset () {
      this.successes = []
      this.failures = []
      this.progress = 0
    }
  }
}
</script>
