<template>
  <div v-if="visible" class="progress-container">
    <div>
      <el-progress v-bind="$attrs" :type="type" />
      <h3>{{ message }}</h3>
    </div>
  </div>
</template>

<script>
/*
* Progress indicator that blocks entire page until finished.
* https://element.eleme.io/#/en-US/component/progress
*/
export default {
  name: 'GlobalProgress',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: 'circle'
    },
    message: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 180; // spreadsheet view seems to have a high z-index
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  ::v-deep .el-progress {
    .el-progress-circle .el-progress-circle__track {
      stroke: #fff;
    }
    .el-progress__text {
      color: #fff;
    }
  }
}
</style>
