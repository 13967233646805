<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
    append-to-body
    @close="$emit('close')"
  >
    <h1>Out of {{ total }} {{ dataType }}:</h1>
    <el-alert
      :title="successes.length + ' were successful'"
      type="success"
      show-icon
      :closable="false"
    />
    <el-alert
      type="error"
      show-icon
      :closable="false"
    >
      <template #title>
        <div class="alert-title" @click="showFailures = !showFailures">
          <span>{{ failures.length }} have failed</span>
          <i :class="showFailures ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" />
        </div>
      </template>
      <el-collapse v-show="showFailures">
        <el-collapse-item
          v-for="(failure, index) in failures"
          :key="get(failure.data, valueKey, index)"
          :title="get(failure.data, valueKey, `Item ${index}`)"
        >
          <pre>{{ failure.error }}</pre>
        </el-collapse-item>
      </el-collapse>
    </el-alert>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="$emit('close')">
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import get from 'lodash.get'
export default {
  props: {
    title: {
      type: String,
      default: 'Batch Results'
    },
    dataType: {
      type: String,
      default: 'items'
    },
    show: {
      type: Boolean,
      required: true
    },
    successes: {
      type: Array,
      required: true
    },
    failures: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showFailures: false
    }
  },
  computed: {
    total () {
      return this.successes.length + this.failures.length
    }
  },
  created () {
    this.get = get
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
  h1 {
    font-size: 1rem;
  }
  .el-alert {
    margin-bottom: 1em;
    padding-right: 0;
    align-items: flex-start;
    .el-alert__icon {
      padding-top: 1px;
      width: 16px;
      font-size: 16px;
    }
    .el-alert__content {
      width: 100%;
      .alert-title {
        display: flex;
        align-items: middle;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 400;

        i {
          margin: auto 7px;
        }
      }
      .el-alert__description {
        margin: 0;
        .el-collapse {
          border: none;
          margin-top: 0.25em;
          .el-collapse-item__header {
            background-color: transparent;
            height: 30px;
            border: none;
            font-weight: 400;
            font-size: 12px;
          }
          .el-collapse-item__wrap {
            background-color: transparent;
            border: none;
            .el-collapse-item__content {
              padding: 0;

              pre {
                background-color: #f5f7fa;
                border: 1px solid #dcdfe6;
                padding: 0 4px;
                margin: 0;
                white-space: break-spaces;
              }
            }
          }
        }
      }
    }
  }
}
</style>
